var init_state = {
    units: "nm",
    csv: [],
    // l: 650,
    // u: 750,
    l: 480,
    u: 666,
    deg: 4,
    direction: "decreasing",
};

const onsetReducer = (state = init_state, action) => {
  switch (action.type) {
    case 'onsetData':
          return {
              ...state,
              units: action.payload.units,
              csv: action.payload.csv,
              l: action.payload.l,
              u: action.payload.u,
              deg: action.payload.deg,
              direction: action.payload.direction
          }
    default:
      return state;
  }
};

export default onsetReducer;
