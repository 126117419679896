import counterReducer from "./counter.js";
import isLoggedReducer from "./isLogged.js"
import dyesReducer from './dyes.js'
import onsetReducer from './onset'
import { combineReducers } from "redux";


const allReducers = combineReducers({
    counter: counterReducer,
    isLogged: isLoggedReducer,
    dyesData: dyesReducer,
    onsetData: onsetReducer
})

export default allReducers
