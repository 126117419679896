import React from "react";
import { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { onsetData } from "../actions/index.js";
// import csv from "csvtojson";

const OnsetForm = () => {
  let fileReader;
  // const state = useSelector((state) => state.onsetData);
  const dispatch = useDispatch();
  const [dat, setDat] = useState({
    units: "nm",
    csv: [],
    l: 650,
    u: 750,
    deg: 4,
    direction: "increasing",
  });
  const handleSubmit = (e) => {
    dispatch(onsetData(dat));
    e.preventDefault();
  };
  const handleFileRead = (e) => {
    try {
      var content = fileReader.result;
      content = content.split("\n");
      var new_cont = [];
      for (let i = 0; i < content.length; i++) {
        let el = content[i];
        el = el.split(",");
        for (let j = 0; j < el.length; j++) {
          const v = el[j];
          el[j] = parseFloat(v);
        }
        content[i] = el;
        new_cont.push(el);
      }
      setDat({ ...dat, csv: new_cont });
    } catch (err) {
      alert("Error with .csv file");
    }
  };

  const handleFileChosen = (file) => {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(file);
  };

  const handleChangeNum = (e, pos) => {
    setDat({
      ...dat,
      [pos]: parseFloat(e.target.value),
    });
  };
  // useEffect(() => {
  //     let csv = require('../AP25.csv')
  //     // handleFileChosen(csv)
  // }, [])

  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label>Lower X Bound:</label>
          <input
            name="l"
            type="text"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            value={dat.l || ""}
            onChange={(e) => handleChangeNum(e, "l")}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <label>Upper X Bound:</label>
          <input
            name="eRange"
            type="text"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            value={dat.u || ""}
            onChange={(e) => handleChangeNum(e, "u")}
          />
        </div>
      </div>
      <br />
      <label>Direction: </label>
      <label>
        <input
          type="radio"
          value="increaing"
          checked={dat.direction === "increasing"}
          onChange={(e) => setDat({ ...dat, direction: e.target.value })}
        />
        increasing
      </label>

      <label>
        <input
          type="radio"
          value="decreasing"
          checked={dat.direction === "decreasing"}
          onChange={(e) => setDat({ ...dat, direction: e.target.value })}
        />
        decreasing
      </label>
      <br />
      <label>Units: </label>
      {/* <label> */}
      {/*   <input */}
      {/*     type="radio" */}
      {/*     value="eV" */}
      {/*     checked={dat.units === "eV"} */}
      {/*     onChange={(e) => setDat({ ...dat, units: e.target.value })} */}
      {/*   /> */}
      {/*   eV */}
      {/* </label> */}

      <label>
        <input
          type="radio"
          value="nm"
          checked={dat.units === "nm"}
          onChange={(e) => setDat({ ...dat, units: e.target.value })}
        />
        nm
      </label>
      <br />
      <label>Polynomial Degree: </label>
      <label>
        <input
          type="radio"
          value="3"
          checked={dat.deg === 3}
          onChange={() => setDat({ ...dat, deg: 3 })}
        />
        3
      </label>

      <label>
        <input
          type="radio"
          value="4"
          checked={dat.deg === 4}
          onChange={() => setDat({ ...dat, deg: 4 })}
        />
        4
      </label>
      <br />
      <input
        type="file"
        name="upload"
        accept=".csv"
        onChange={(e) => handleFileChosen(e.target.files[0])}
      />
      <br />
      <input type="submit" value="Submit" />
    </form>
  );
};

export default OnsetForm;
