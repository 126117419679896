import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import React from "react";
import { onsetFunction } from "./OnsetCalc";

const normalize = (data) => {
  var max = 0;

  for (let i = 0; i < data.length; i++) {
    if (data[i].y > max) {
      max = data[i].y;
    }
  }
  for (let i = 0; i < data.length; i++) {
    data[i].y = data[i].y / max;
  }
  return data;
};

const generate_peaks_1d = (nm, osci, s) => {
  var gaus = [];
  var dp = {};
  var y = 0;
  for (let x = 200; x < 1200; x++) {
    y =
      Math.exp(-Math.pow(x - nm, 2) / (2 * Math.pow(s, 2))) /
      (s * Math.sqrt(2 * Math.PI));

    dp = {
      x: x,
      y: y,
    };
    gaus.push(dp);
  }
  return gaus;
};

const generate_spectrum = (excs, coef) => {
  var excs2 = [];
  var highest_nm = 0;
  for (let i = 0; i < excs.length; i++) {
    const el = excs[i];
    if (el.method_basis_set == "LSF") {
      excs2.push({ osci: el.osci, nm: el.nm });
      if (highest_nm < el.nm) {
        highest_nm = el.nm;
      }
    }
  }
  var gaussians = [];
  var highest_osci = 0;
  for (let i = 0; i < excs2.length; i++) {
    const el = excs2[i];
    const s = coef[0] * el.nm + coef[1];
    var gaus = generate_peaks_1d(el.nm, el.osci, s);
    gaussians.push(gaus);
    if (highest_osci < el.osci) {
      highest_osci = el.osci;
    }
  }
  var g_fin = gaussians[0];
  var onset = onsetFunction(g_fin, [
    highest_nm,
    highest_nm + highest_nm * 0.25,
  ]);
  // console.log("lsf onset", onset.onset);
  for (let i = 1; i < gaussians.length; i++) {
    for (let j = 1; j < gaussians[i].length; j++) {
      g_fin[j].y += gaussians[i][j].y;
    }
  }
  g_fin = normalize(g_fin);
  return { g_fin, onset: parseInt(onset.onset) };
};

const ArtificialSpectrum = (excs, coef) => {
  const { g_fin: data, onset } = generate_spectrum(excs, coef);
  var xs = [];
  for (let i = 0; i < data.length; i++) {
    xs.push(data[i].x);
  }
  const dataset = {
    datasets: [
      {
        id: "Guassian",
        label: "Artificial Spectrum",
        data: data,
        pointRadius: 1,
        borderColor: "red",
        // borderWidth: 2,
        showLine: true,
        type: "line",
      },
    ],
  };
  var aspectRatio = 2;
  if (window.screen.width > 1.5 * window.screen.height) {
    aspectRatio = 3;
  } else if (window.screen.width > window.screen.height) {
    aspectRatio = 2;
  } else {
    aspectRatio = 1;
  }
  return (
    <div>
      <h2 style={{ textAlign: "center" }}>
        The LSF lambda onset value is {onset}
      </h2>
      <div>
        <Line
          datasetIdKey="id1"
          data={dataset}
          options={{
            type: "line",
            scaleShowLabels: false,
            responsive: true,
            maintainAspectRatio: true,
            aspectRatio: aspectRatio,
            scales: {
              y: {
                title: {
                  display: true,
                  text: "Abs.",
                },
              },
              x: {
                labels: xs,
                title: {
                  display: true,
                  text: "Wavelength (nm)",
                },
              },
            },
            plugins: {
              legend: {
                position: "top",
              },
              title: {
                display: true,
                text: "Dyes",
              },
            },
          }}
        />
      </div>
    </div>
  );
};

const ArtSpec = ({ dye, coef }) => {
  const excs = dye.excitations;

  return (
    <div style={{ flex: 1 }}>
      {/* <p>Artificial Spectrum</p> */}
      {ArtificialSpectrum(excs, coef)}
    </div>
  );
};

export default ArtSpec;
