import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import test from "../test.js";
import PolynomialRegression from "js-polynomial-regression";
import { Scatter } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

const onsetFunction = (
  data_input,
  range = [500, 600],
  deg = 4,
  direction = "increasing"
) => {
  if (data_input.length > 20) {
    if (data_input[0].x) {
      var dat = [];
      for (let i = 0; i < data_input.length; i++) {
        dat.push([data_input[i].x, data_input[i].y]);
      }
      data_input = dat;
    }
    data_input.sort((a, b) => {
      return a[0] - b[0];
    });
    var input = simpleMovingAverage(data_input);
    // var input = simpleMovingAverage(test);
    input.sort((a, b) => {
      return a[0] - b[0];
    });
    if (range[0] > range[1]) {
      var tmp = range[1];
      range[1] = range[0];
      range[0] = tmp;
    }
    const { onsetObj } = steepestLineInput(input, range, deg, direction);
    return onsetObj;
  } else {
    console.log("not long enough data");
    return {};
  }
};

const deriv = (data) => {
  // takes data of x and y to produce numerical derivative
  if (data.length === 0) {
    return [];
  }
  var d = Array(data.length - 1).fill({ x: 0, y: 0 });
  for (let i = 0; i < data.length - 1; i++) {
    d[i] = {
      x: (data[i + 1].x + data[i].x) / 2,
      y: (data[i + 1].y - data[i].y) / (data[i + 1].x - data[i].x),
    };
  }
  return d;
};

const conv2dToObj = (data) => {
  var dat = [];
  for (let i = 0; i < data.length; i++) {
    const el = data[i];
    dat.push({
      x: el[0],
      y: el[1],
    });
  }
  return dat;
};

const convObTo2d = (data) => {
  var dat = [];
  for (let i = 0; i < data.length; i++) {
    const el = data[i];
    dat.push([el.x, el.y]);
  }
  return dat;
};

const acquireMaxima = (data) => {
  var max = { x: 0, y: 0 };
  for (let i = 0; i < data.length; i++) {
    if (data[i][1] > max.y) {
      max = {
        x: data[i][0],
        y: data[i][1],
      };
    }
  }
  return { maxPoint: max };
};

const normalize = (data) => {
  var max = 0;
  var cutInd = 0;
  var maxPoint = { x: 0, y: 0 };

  for (let i = 0; i < data.length; i++) {
    if (data[i][1] > max) {
      max = data[i][1];
      maxPoint = {
        x: data[i][0],
        y: data[i][1],
      };
    }
  }
  for (let i = 0; i < data.length; i++) {
    data[i][1] = data[i][1] / max;
  }
  return { data: data.slice(cutInd, data.length), maxPoint: maxPoint };
};
const cutToMax = (data) => {
  if (data.length === 0) {
    return data;
  }
  if (!data[0].x) {
    var max = 0;
    var cutInd = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i][1] > max) {
        max = data[i][1];
        cutInd = i;
      }
    }
  } else {
    var max = 0;
    var cutInd = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].y > max) {
        max = data[i].y;
        cutInd = i;
      }
    }
  }
  return { data: data.slice(cutInd, data.length), cutInd };
};

const cutAfterMin = (data) => {
  if (data.length === 0) {
    return data;
  }
  console.log("cutMin");
  console.log("cutMin", data);
  var d1Min = 9999999;
  var cutInd = data.length;
  if (!data[0].x) {
    data = conv2dToObj(data);
  }
  const d1 = deriv(data);
  for (let i = 2; i < d1.length - 2; i++) {
    if (i > parseInt(0.2 * data.length)) {
      var check = Math.abs(d1[i].y) < Math.abs(d1Min);
      var pos = Math.abs(d1[i].y);
      var posn = Math.abs(d1[i - 2].y);
      var posn2 = Math.abs(d1[i - 4].y);
      var posp = Math.abs(d1[i + 2].y);
      if (check && pos < posn && pos < posp) {
        cutInd = i;
        d1Min = d1[i].y;
      }
      if (pos > posn && pos > posn2 && d1Min !== 9999999) {
        break;
      }
    }
  }
  return { data: data.slice(0, cutInd), cutInd };
};

const filterArray = (data, range, direction) => {
  // data = data.sort(function (a, b) {
  //   return a - b;
  // });
  data = data.sort(function (a, b) {
    if (direction === "increasing") {
      return a[0] - b[0];
    }
    return b[0] - a[0];
  });

  var maxPoint = { x: 0, y: 0 };
  var { data, maxPoint } = normalize(data);
  if (range[0] === range[1]) {
    console.log("range match");
    return data;
  }
  // if (range[0] === range[1]) {
  //   console.log("range match");
  //   return data;
  // } else if (range[0] > range[1]) {
  //   console.log("lower bound was higher than higher bound. Flipping range");
  //   var tmp = range[1];
  //   range[1] = range[0];
  //   range[0] = tmp;
  //   // return data;
  // }
  var start = false;
  var selection = [0, 0];
  if (direction === "increasing") {
    for (let i = 0; i < data.length; i++) {
      const el = data[i][0];
      if (!start && el > range[0]) {
        start = true;
        selection[0] = i;
      }
      if (start && el > range[1]) {
        selection[1] = i;
        break;
      }
      if (start && i === data.length - 1) {
        selection[1] = i;
      }
    }
    if (!start) {
      console.log("range wasn't good");
    }
    return { data: data.slice(selection[0], selection[1]), maxPoint };
  } else {
    for (let i = 0; i < data.length; i++) {
      const el = data[i][0];
      if (!start && el < range[1]) {
        start = true;
        selection[1] = i;
      }
      if (start && el < range[0]) {
        selection[0] = i;
        break;
      }
      if (start && i === data.length - 1) {
        selection[0] = i;
      }
    }
    if (!start) {
      console.log("range wasn't good");
    }
    return { data: data.slice(selection[1], selection[0]), maxPoint };
  }
};

const polyFitting = (data, deg, arr2d = true) => {
  if (arr2d) {
    var data = conv2dToObj(data);
  }
  // if (direction==='decreasing'){
  //     data.sort((a,b) => {return a.x - b.x})
  // }

  const model = PolynomialRegression.read(data, deg);
  const terms = model.getTerms();
  var pred = [];
  for (let j = 0; j < data.length; j++) {
    const el = data[j];
    const y = model.predictY(terms, el.x);
    pred.push({
      x: el.x,
      y,
    });
  }
  return pred;
};

const saddlePoint = (d2) => {
  var lowest = 100000;
  var sp = { x: 0, y: 0 };
  for (let i = 0; i < d2.length; i++) {
    const v = Math.abs(d2[i].y);
    if (v < lowest) {
      lowest = v;
      sp = d2[i];
    }
  }
  return sp;
};

const acquireEquationValues = (data, d1, sp) => {
  var v = { x: 0, y: 0 };
  var v1 = { x: 0, y: 0 };

  for (let i = 0; i < data.length; i++) {
    if (data[i].x === sp.x) {
      v = data[i];
    }
  }
  if (v === { x: 0, y: 0 }) {
    return { v, v1 };
  }

  // TODO: make 0.5 dependent on the difference
  //step = abs(data[1, 0] - data[0,0])

  for (let i = 0; i < d1.length - 1; i++) {
    if (d1[i].x + 0.5 === sp.x) {
      v1 = {
        x: (d1[i].x + d1[i + 1].x) / 2,
        y: (d1[i].y + d1[i + 1].y) / 2,
      };
    }
  }
  if (v1 === { x: 0, y: 0 }) {
    return { v, v1 };
  }
  return { v, v1 };
};

const acquireOnset = (v, v1, sp) => {
  var onset;
  var slope = v1.y;
  var b = v.y - v1.y * sp.x;
  // console.log("line_formula = ", v1.y, "x +", b);
  var onset = -b / v1.y;
  return { slope, b, onset };
};

const generateLine = (slope, b, start, onset, direction) => {
  var ys = [];
  if (direction === "increasing") {
    for (let i = start; i < onset + 20; i++) {
      const y = slope * i + b;
      if (y < 1.2 && y > 0) {
        ys.push({
          x: i,
          y: y,
        });
      }
    }
  } else {
    for (let i = onset; i < start + 20; i++) {
      const y = slope * i + b;
      if (y < 1.2 && y > 0) {
        ys.push({
          x: i,
          y: y,
        });
      }
    }
    ys.sort((a, b) => {
      return b.x - a.x;
    });
  }
  return ys;
};

const plotDataLine = (vs, title, xRange = [200, 800], yRange = [0, 1.2]) => {
  if (vs[0].data.length === 0) {
    return null;
  }
  var datasets = { datasets: [] };
  for (let i = 0; i < vs.length; i++) {
    // for (let i = 0; i < 1; i++) {
    datasets.datasets.push({
      id: i,
      label: vs[i].label,
      data: vs[i].data,
      pointRadius: vs[i].pointRadius,
      borderColor: vs[i].color,
      borderWidth: 2,
      showLine: false,
    });
  }

  console.log(datasets);
  return (
    <Scatter
      datasetIdKey="id1"
      style={{ flex: 1, maxWidth: "75%" }}
      data={datasets}
      options={{
        scaleShowLabels: false,
        responsive: true,
        // maintainAspectRatio: false,
        scales: {
          y: {
            min: yRange[0],
            max: yRange[1],
            title: {
              display: true,
              text: "Abs.",
            },
          },
          x: {
            min: xRange[0],
            max: xRange[1],
            labels: [],
            title: {
              display: true,
              text: "Wavelength (nm)",
            },
            display: "auto",
            ticks: {
              textStrokeWidth: 0,
              textStrokeColor: "white",
            },
          },
        },
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: title,
          },
        },
      }}
    />
  );
};

const simpleMovingAverage = (data) => {
  for (let i = 5; i < data.length - 5; i++) {
    // data[i][1] = 1
    data[i][1] =
      (data[i - 5][1] +
        data[i - 4][1] +
        data[i - 3][1] +
        data[i - 2][1] +
        data[i - 1][1] +
        data[i][1] +
        data[i + 5][1] +
        data[i + 4][1] +
        data[i + 3][1] +
        data[i + 2][1] +
        data[i + 1][1]) /
      10;
  }
  return data;
};

const closeToZero = (d2) => {
  var points = 0;
  for (let i = 0; i < d2.length; i++) {
    if (Math.abs(d2[i].y) < 1e-6) {
      points++;
    }
  }
  return points;
};

const scoreRange = (points, slope, direction) => {
  if (direction === "increasing") {
    return points - 1.5 * 1000 * slope;
  } else {
    return points + 1.5 * 1000 * slope;
  }
};

const steepestLineInput = (input, range, deg, direction) => {
  var onsetObj = {
    pred: [],
    slope: 0,
    b: 0,
    onset: 0,
    d1: [],
    d2: [],
    score: 0,
    lineStart: 0,
    line: [],
  };
  const dif = range[1] - range[0];
  var size = 1;
  if (dif >= 100) {
    size = 10;
  } else if (dif >= 50) {
    size = 6;
  } else if (dif >= 20) {
    size = 4;
  } else if (dif > 10) {
    size = 2;
  }
  size = 0;
  for (let i = 0; i <= size; i++) {
    if (direction === "increasing") {
      range[0] = range[0] + 4;
      range[1] = range[1] + 4;
    } else {
      range[0] = range[0] - 4;
      range[1] = range[1] - 4;
    }
    var { data: fil_arr } = filterArray(input, range, direction);
    var pred1 = polyFitting(fil_arr, deg);
    var { data: fil_arr } = cutToMax(pred1);
    var { data: fil_arr } = cutAfterMin(fil_arr);
    console.log("fil_arr", fil_arr);
    fil_arr = convObTo2d(fil_arr);
    var pred = polyFitting(fil_arr, deg);
    const d1 = deriv(pred);
    const d2 = deriv(d1);
    const sp = saddlePoint(d2);
    const { v, v1 } = acquireEquationValues(pred, d1, sp);
    const { slope, b, onset } = acquireOnset(v, v1, sp);
    const points = closeToZero(d2);
    const score = scoreRange(points, slope, direction);
    // if (direction === 'decreasing'){
    //     pred.sort((a,b) => {return a.x - b.x})
    // }
    if (score > onsetObj.score) {
      onsetObj = {
        pred,
        slope,
        b,
        onset,
        d1,
        d2,
        score,
        lineStart: parseInt(fil_arr[0][0] - 20),
        line: [],
      };
    }
  }
  const line = generateLine(
    onsetObj.slope,
    onsetObj.b,
    onsetObj.lineStart,
    parseInt(onsetObj.onset),
    direction
  );
  onsetObj = { ...onsetObj, line: line };
  return { onsetObj };
};

const OnsetCalc = () => {
  const [vs, setVs] = useState({
    arr: [],
    pred: [],
    d1: [],
    d2: [],
    line: [],
  });
  const [onset, setOnset] = useState(null);
  const [ranges, setRanges] = useState([0, 0]);
  const [plotR, setPlotR] = useState({
    xr: [0, 0],
    yr: [0, 0],
  });
  const [lambda, setLambda] = useState();
  const [errMsg, setErrMsg] = useState([]);
  const state = useSelector((state) => state.onsetData);
  // const dispatch = useDispatch();
  useEffect(() => {
    const processForm = () => {
      var errs = [];
      try {
        // change every instance of test with state.csv for real test
        // console.log("starting state:", state);
        if (state.csv.length > 20) {
          // if (test.length > 20) {
          console.log("useEffect data", state.csv);
          // test.sort((a, b) => {
          //   return a[0] - b[0];
          // });
          state.csv.sort((a, b) => {
            return a[0] - b[0];
          });
          var input = simpleMovingAverage(state.csv);
          // var input = simpleMovingAverage(test);
          input.sort((a, b) => {
            return a[0] - b[0];
          });
          console.log(input);
          var range = [state.l, state.u];
          if (range[0] > range[1]) {
            errs.push(
              "Lower X Bound was greater than Upper X Bound. Flipping bounds"
            );
            var tmp = range[1];
            range[1] = range[0];
            range[0] = tmp;
          }
          setRanges(range);

          var { data: fil_arr } = filterArray(input, range, state.direction);
          var { maxPoint } = acquireMaxima(fil_arr);
          setLambda(maxPoint);
          setPlotR({
            ...plotR,
            xr: [state.csv[0][0], state.csv[state.csv.length - 1][0]],
            // xr: [test[0][0], test[test.length - 1][0]],
          });
          const { onsetObj } = steepestLineInput(
            input,
            range,
            state.deg,
            state.direction
            // "decreasing"
          );
          setOnset(onsetObj.onset);
          setVs({
            // arr: state.csv,
            arr: input,
            pred: onsetObj.pred,
            d1: onsetObj.d1,
            d2: onsetObj.d2,
            line: onsetObj.line,
          });
          setErrMsg(errs);
        } else {
          errs.push("No data submitted.");
          setErrMsg(errs);
        }
      } catch (err) {
        errs.push(
          "An error occurred processing the data. Please contact austinwallace196@gmail.com if the problem cannot be resolved"
        );
        setErrMsg(errs);
        console.log(err);
      }
    };
    processForm();
  }, [state]);

  const displayPlots = (range) => {
    if (range[0] === range[1]) {
      return (
        <div style={{ maxWidth: "75%", border: "2px solid red" }}>
          {plotDataLine(
            [
              {
                label: "Simple Moving Average of Data",
                data: vs.arr,
                color: "black",
                pointRadius: 1,
              },
            ],
            "Uploaded Data",
            plotR.xr
          )}
        </div>
      );
    }
    console.log("dis", vs.line, vs.pred);
    return (
      <div>
        {plotDataLine(
          [
            {
              label: "Onset Line",
              data: vs.line,
              color: "blue",
              pointRadius: 0.2,
            },
            {
              label: "Selected Polynomial Fitting",
              data: vs.pred,
              color: "red",
              pointRadius: 1,
            },
            {
              label: "Simple Moving Average of Data",
              data: vs.arr,
              color: "black",
              pointRadius: 1,
            },
          ],
          "Data with Polynomial Fitting and Tangent Line through Inflection Point",
          plotR.xr
        )}
        {plotDataLine(
          [
            {
              label: "First Derivative",
              data: vs.d1,
              color: "green",
              pointRadius: 0.5,
            },
            {
              label: "Second Derivative",
              data: vs.d2,
              color: "black",
              pointRadius: 0.2,
            },
          ],
          "First and Second Derivative Plots",
          plotR.xr,
          [-0.01, 0.01]
        )}
      </div>
    );
  };

  const displayErrors = (errMsg) => {
    if (errMsg.length === 0) {
      return null;
    }
    const listItems = errMsg.map((msg) => (
      <li key={msg} style={{ color: "red" }}>
        {msg}
      </li>
    ));
    return <ul>{listItems}</ul>;
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <p>
        After uploading data, please use the graph to give a lower energy bound
        at the peak maxima of interest and an upper energy bound of the
        following local peak minimum.
      </p>
      {displayErrors(errMsg)}
      {onset ? (
        <h1 style={{ alignSelf: "center" }}>
          The onset value is {onset.toFixed(1)} nm
        </h1>
      ) : null}
      {lambda ? (
        <p>
          The lambda max is ({lambda.x}, {lambda.y.toFixed(2)})
        </p>
      ) : null}
      {displayPlots(ranges)}
    </div>
  );
};

// export default OnsetCalc;
export { onsetFunction, OnsetCalc };
