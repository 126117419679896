import React from "react";
import "./DyeDataListing.css";

export default function ContactButton({ text }) {
  return (
    <div style={{ alignSelf: "center", alignContent: "center" }}>
      <p style={{ overflowWrap: "break-word" }}>
        {text}
        <a href={"mailto:austinwallace196@gmail.com"} style={{ alignSelf: 'center', textAlign: 'center'}}>Email</a>
      </p>
    </div>
  );
}
