export const increment = (num) => {
  return {
    type: "INCREMENT",
    payload: num,
  };
};
export const decrement = (num) => {
  return {
    type: "DECREMENT",
    payload: num,
  };
};

export const logIn = () => {
  return {
    type: "log_in",
  };
};

export const addDye = (dye) => {
  return {
    type: "addDye",
    payload: dye,
  };
};

export const nextDye = () => {
    return {
        type: "nextDye"
    }
}

export const prevDye = () => {
    return {
        type: "prevDye"
    }
}

export const initDyes = (_) => {
  return {
    type: "initDyes",
  };
};

export const selectedData = (dat) => {
  return {
    type: "selectedData",
    payload: dat,
  };
};

export const onsetData = (dat) => {
  return {
    type: "onsetData",
    payload: dat,
  };
};
