import results_exc from "../test2.json";

var init_state = {
  molecules: [],
  molecules_active: [],
  plot: {
    dataset: [],
    labels: [],
  },
  pos: 0,
  init: false,
};

// const conv = (val) => {
//   return (6.62607004e-34 * 3e17) / (val * 1.602e-19);
// };

// const refSort = (targetData, refData) => {
//   // Create an array of indices [0, 1, 2, ...N].
//   var indices = Object.keys(refData);
//
//   // Sort array of indices according to the reference data.
//   indices.sort(function (indexA, indexB) {
//     if (refData[indexA] < refData[indexB]) {
//       return -1;
//     } else if (refData[indexA] > refData[indexB]) {
//       return 1;
//     }
//     return 0;
//   });
//
//   // Map array of indices to corresponding values of the target array.
//   return indices.map(function (index) {
//     return targetData[index];
//   });
// };

const resultsToPlot = (molecules, eRange) => {
  var colors = ["red", "blue", "green", "orange"];
  var mol = {};
  var exc = [];
  var labels = [];
  var dataset = [];
  console.log("eRange:", eRange);
  for (let i = 0; i < eRange.methods.length; i++) {
    dataset.push({
      id: i,
      label: eRange.methods[i],
      data: [],
      pointRadius: 1,
      borderColor: colors[i],
      borderWidth: 2,
      showLine: false,
    });
  }
  var added = false;
  var active_mols = [];
  for (let i = 0; i < molecules.length; i++) {
    mol = molecules[i];
    added = false;
    for (let j = 0; j < mol.excitations.length; j++) {
      exc = mol.excitations;
      for (let k = 0; k < eRange.methods.length; k++) {
        if (
          exc[j].exc === 1 &&
          eRange.methods[k] === exc[j].method_basis_set &&
          mol.lsf > eRange.l &&
          mol.lsf < eRange.u
        ) {
          dataset[k].data.push({ x: mol.localName, y: exc[j].nm });
          if (!added) {
            labels.push(mol.localName);
            added = true;
            if (active_mols.length < 50) {
              active_mols.push(mol);
            }
          }
          // break;
        }
      }
    }
  }
  if (labels.length === 0) {
    dataset = [];
  } else {
    for (let d = 0; d < dataset.length; d++) {
      var el = dataset[d];
      el.data.sort(function (a, b) {
        return a.y - b.y;
      });
      dataset[d] = el;
    }
    // var dat = refSort(dataset[0])
    // dataset.sort((a,b)=>{
    //     var c = a[0].data
    //     var d = b[0].data
    //     return
    // })
  }
  return { labels, dataset, active_mols };
};

// const molecule = {
//   SMILES: "",
//   excitations: [
//     {
//       HOMO: 0,
//       LUMO: 0,
//       exc: 1,
//       method_basis_set: "CAM-B3LYP/6-311G(d,p)",
//       nm: 529.74,
//       orbital_Numbers: [142, 144, -0.20707],
//       osci: 0.9519,
//     },
//   ],
//   generalSMILES: "",
//   localName: "",
//   name: "",
//   parts: "",
// };

const init = (molecules) => {
  for (let k = 0; k < molecules.length; k++) {
    const dye = molecules[k];
    molecules[k].lsf = 1;
    const dye_exc = dye.excitations;
    for (let j = 0; j < dye_exc.length; j++) {
      if (dye_exc[j].method_basis_set == "LSF" && dye_exc[j].exc == 1) {
        molecules[k].lsf = dye_exc[j].nm;
      }
    }
  }
};
// init_state.molecules = init(results_exc.molecules)
init_state.molecules = results_exc.molecules;

const dyesReducer = (state = init_state, action) => {
  switch (action.type) {
    case "initDyes":
      if (!state.init) {
        var tmp = state.molecules;
        init(tmp);
        console.log("init:", tmp[0]);
        return { ...state, molecules: tmp, init: true };
      }
      return { ...state };
    case "nextDye":
      if (state.pos < state.molecules_active.length) {
          const t = state.pos + 1
          console.log('increment', state.pos)
        return { ...state, pos: t };
      } else {
        return { ...state };
      }
    case "prevDye":
      if (state.pos > 0) {
          const t = state.pos - 1
        return { ...state, pos: t};
      } else {
        return { ...state };
      }

    case "addDye":
      var tmp = state.molecules;
      tmp.push(action.payload);
      return { ...state, molecules: tmp };
    case "selectedData":
      // var { labels, dataset } = filterMolecules(
      //   state.molecules,
      //   action.payload
      // );
      var { labels, dataset, active_mols } = resultsToPlot(
        state.molecules,
        action.payload
      );

      return {
        ...state,
        plot: {
          dataset,
          labels,
        },
        molecules_active: active_mols,
      };
    default:
      return state;
  }
};

export default dyesReducer;
