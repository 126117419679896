import React from "react";
import '../App.css'

const Objectives = () => {
  return (
    <div className="obj-div">
      <h1>Objectives</h1>
      <p>
        We provide theortical sensitized-dye predictions through the use of
        time-dependent density functional theory (TD-DFT) with CAM-B3LYP and
        PBE0.
      </p>
      <p>
      Additionally, this website provides an easy interface for calculating
      lambda onset values.
      </p>
    </div>
  );
};

export default Objectives;
