import React from "react";
import { useState, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { initDyes, selectedData } from "../actions/index.js";

const DyeForm = () => {
  // const dyes = useSelector((state) => state.dyesData);
  const dispatch = useDispatch();
    useEffect(()=> {
        dispatch(initDyes())
    }, [])
  const [eRange, setERange] = useState({
    l: 300,
    u: 1000,
    units: "nm",
    eType: "exc",
    methods: [],
  });
  const handleChangeNum = (e, pos) => {
    setERange({
      ...eRange,
      [pos]: parseFloat(e.target.value),
    });
  };
  const handleSubmit = (e) => {
    dispatch(selectedData(eRange));
    e.preventDefault();
  };

  const handleSubmitMethods = (data) => {
    const meths = [];
    for (let i = 0; i < data.length; i++) {
      meths.push(data[i].value);
    }
    setERange({ ...eRange, methods: meths });
  };

  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <label>Units: </label>
      {
          /*
      <label>
        <input
          type="radio"
          value="eV"
          checked={eRange.units === "eV"}
          onChange={(e) => setERange({ ...eRange, units: e.target.value })}
        />
        eV
      </label>
          */
      }
      <label>
        <input
          type="radio"
          value="nm"
          checked={eRange.units === "nm"}
          onChange={(e) => setERange({ ...eRange, units: e.target.value })}
        />
        nm
      </label>
      <br />
      <label>Energy Type: </label>
      <label>
        <input
          type="radio"
          value="exc"
          checked={eRange.eType === "exc"}
          onChange={() => setERange({ ...eRange, eType: "exc" })}
        />
        Excitation Energy
      </label>
      {/*
      <label>
        <input
          type="radio"
          value="homo"
          checked={eRange.eType === "homo"}
          onChange={(e) => setERange({ ...eRange, eType: e.target.value })}
        />
        HOMO
      </label>
      <label>
        <input
          type="radio"
          value="lumo"
          checked={eRange.eType === "lumo"}
          onChange={(e) => setERange({ ...eRange, eType: e.target.value })}
        />
        LUMO
      </label>

          */
      }
      <br />
      <label>
        Select the methods you want to query below. (Hold CTRL or CMD while
        clicking for multiple)
        <br />
        <select
          multiple={true}
          value={eRange.methods}
          onChange={(e) => handleSubmitMethods(e.target.selectedOptions)}
        >
          <option value="CAM-B3LYP/6-311G(d,p)">CAM-B3LYP/6-311G(d,p)</option>
          <option value="LSF">LSF (CAM-B3LYP + PBE0)</option>
          <option value="PBE1PBE/6-311G(d,p)">PBE0/6-311G(d,p)</option>
          <option value="bhandhlyp/6-311G(d,p)">bhandhlyp/6-311G(d,p)</option>
        </select>
      </label>
      <br />

      <br />
      <label>Lower Energy Bound (LSF):</label>
      <br />
      <input
        name="eRange"
        type="text"
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        value={eRange.l}
        onChange={(e) => handleChangeNum(e, "l")}
      />
      <br />
      <label>Upper Energy Bound (LSF):</label>
      <br />
      <input
        name="eRange"
        type="text"
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        value={eRange.u}
        onChange={(e) => handleChangeNum(e, "u")}
      />
      <br />
      <input type="submit" value="Submit" />
    </form>
  );
};

export default DyeForm;
