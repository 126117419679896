import React from 'react';
import './App.css';
import { Link } from 'react-router-dom';


function Nav() {
  return (
    <nav>
        <div className="Nav-container">
        {/* <img src={GroupLogo} alt='grouplogo' width='50' height='60' /> */}
        <ul>
            <Link className="link-no-dec" to='/'>
                <li>Objectives</li>
            </Link>
            <Link className="link-no-dec" to='/Dyes'>
                <li>Dyes</li>
            </Link>
            <Link className="link-no-dec" to='/Onset'>
                <li>0nset</li>
            </Link>
        </ul>
        </div>
    </nav>
  );
}

export default Nav;
