import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#a33434",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

// const rows = [
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
// ];

export default function CustomizedTables({excitations: exc}) {
    console.log('excitations', exc)
  var rows = [];
  for (let i = 0; i < exc.length; i++) {
    var displayName = exc[i].method;
    if (exc[i].method === "bhandhlyp / 6-311G(d,p)") {
      displayName = "BHandHLYP / 6-311G(d,p)";
    }
    const d = {
      method: displayName,
      nm: exc[i].nm,
      osci: exc[i].osci,
      homo: exc[i].HOMO,
      lumo: exc[i].LUMO,
    };
      console.log(d)
    rows.push(d);
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Method</StyledTableCell>
            <StyledTableCell align="right">Abs. (nm)</StyledTableCell>
            <StyledTableCell align="right">Oscillator Strength</StyledTableCell>
            <StyledTableCell align="right">HOMO (eV)</StyledTableCell>
            <StyledTableCell align="right">LUMO (eV)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.method}>
              <StyledTableCell component="th" scope="row">
                {row.method}
              </StyledTableCell>
              <StyledTableCell align="right">{row.nm}</StyledTableCell>
              <StyledTableCell align="right">{row.osci}</StyledTableCell>
              <StyledTableCell align="right">{row.homo}</StyledTableCell>
              <StyledTableCell align="right">{row.lumo}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
