import React from "react";
import DyeForm from "../components/DyeForm";
import DyePlot from '../components/DyePlot'
import DyeListingGraph from '../components/DyeListingGraphs'
import "../App.css"

const Dyes = () => {
  return (
    <div className="Dyes-Container">
      <h1>Dyes</h1>
      <DyeForm />
      <DyePlot/>
      <DyeListingGraph/>
    </div>
  );
};

export default Dyes;
