import React from "react";
import Nav from "./Nav.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Objectives from "./routes/Objectives";
import NotFound from "./routes/NotFound";
import Dyes from './routes/Dyes'
import Onset from './routes/Onset'

function App() {
  return (
    <Router>
      <div className="App">
      <Nav/>
        <Routes>
            <Route exact path="/" element={<Objectives/>} />
            <Route path="/Dyes" element={<Dyes/>} />
            <Route path="/Onset" element={<Onset/>} />
            <Route path="*" element={<NotFound/>} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
