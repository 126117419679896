import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { useSelector } from "react-redux";

const DyePlot = () => {
  const dyes = useSelector((state) => state.dyesData);
  const data = {
    datasets: dyes.plot.dataset,
  };
    var aspectRatio = 2
    if (window.screen.width > 1.5 * window.screen.height){
        aspectRatio = 3
    } else if (window.screen.width > window.screen.height){
        aspectRatio = 2
    } else{
        aspectRatio = 1
    }
  console.log("DATASET", data);
  return (
    <div>
      <Line
        datasetIdKey="id1"
        data={data}
        options={{
          scaleShowLabels: false,
          responsive: true,
          maintainAspectRatio: true,
          aspectRatio: aspectRatio,
          scales: {
            y: {
              title: {
                display: true,
                text: "Abs. Max (nm)",
              },
            },
            x: {
              labels: [],
              title: {
                display: true,
                text: "Theoretical Dyes (single dye representative for region)",
              },
              ticks: {
                textStrokeWidth: 0,
                textStrokeColor: "white",
              },
            },
          },
          plugins: {
            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: "Dyes",
            },
          },
        }}
      />
    </div>
  );
};

export default DyePlot;
