import React from "react";
import OnsetForm from "../components/OnsetForm";
import { OnsetCalc } from "../components/OnsetCalc.js";
import OnsetCite from '../components/OnsetCite'
import "../App.css";

const Onset = () => {
  return (
    <div className="Onset-Container">
      <h1>0nset</h1>
      <OnsetCite/>
      <OnsetForm />
      <OnsetCalc />
    </div>
  );
};

export default Onset;
